import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"

const FAQ = () => {
  return (
    <Layout>
      <h1 className="text-center pt-8">Frequently Asked Questions</h1>
      <ul className="mx-auto w-4/5 divide-y-2">
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">Q: Why CSV files?</h3>
          <p className="italic ml-4">
            A: We support{" "}
            <a
              href="https://en.wikipedia.org/wiki/Comma-separated_values"
              target="_blank"
            >
              CSV
            </a>{" "}
            as most data tools and database programs supports exporting the
            files as csv.
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">Q: Do you support excel files?</h3>
          <p className="italic ml-4">
            A: Not at the moment but support for uploading excel files will be
            added in a future update.
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">
            Q: How do I turn my file into a CSV file?
          </h3>
          <p className="italic ml-4">
            A: There are many ways to do it. If your getting your data from a
            tool check if you can save it as a .csv file or export it. If you
            have microsoft excel you can choose to export your file as csv. If
            you need help contact us at <code>contact@explot.io</code>
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">Q: Can I save my charts?</h3>
          <p className="italic ml-4">
            A: Unfortunately you can't save your files right now as we don't
            save your data, but in the future we might support the ability to
            load in a complete workspace (but without the file). In the meantime
            if you want to share your insights we recomend to take a
            screencapture or screenshort of your chart/data.
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">
            Q: Can I upload and save my files in the cloud?
          </h3>
          <p className="italic ml-4">
            A: No, we don't have the infrastructure to host your files on the
            cloud. If you or your organization wants to host their files on the
            cloud for easy access and sharing across the team please contact us
            at <code>contact@explot.io</code>
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">Q: Is my data safe?</h3>
          <p className="italic ml-4">
            A: <b>Probably</b> Explot uses the latest client side browser
            web-technology to analyse your data without it leaving your computer
            which means we don't send your data through any network requests.
            But its only as safe as your computer and web browser is. Please
            always have an updated browser and anti-virus programs running.
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">Q: Can I use Explot on my phone?</h3>
          <p className="italic ml-4">
            A: No, Explot uses the web-browser to do heavy calculations a phone
            can't handle it. <b>Yet</b>
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">
            Q: Can I share my workspace with teammates or others?
          </h3>
          <p className="italic ml-4">
            A: That's something we are working on but as of now it only exists
            on your browser while you are working on it and disapears as soon as
            you leave.
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">
            Q: Can I integrate with my data from X?
          </h3>
          <p className="italic ml-4">
            A: We are constantly working on letting you import data from
            different tools, if you have a special request or suggestion for
            tools you would like to pull data from contact us at{" "}
            <code>contact@explot.io</code> and we will look into it.
          </p>
        </li>
        <li className="py-2 my-2">
          <h3 className="text-lg mb-2">Q: I need to get in touch with you</h3>
          <p className="italic ml-4">
            {" "}
            Message us at
            <code>contact@explot.io</code>
          </p>
        </li>
      </ul>
    </Layout>
  )
}

export default FAQ
